import React from "react";
import "./assets/Footer.css"; // Import the stylesheet

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <ul className="footer-links">
          <li>
            <a href="/terms-and-conditions" className="footer-link">
              Terms and Conditions
            </a>
          </li>
          <li>
            <a href="/privacy-policy" className="footer-link">
              Privacy Policy
            </a>
          </li>
          <li>
            <a
              href="mailto:info@fit-mate24.com"
              className="footer-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              info@fit-mate24.com
            </a>
          </li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
