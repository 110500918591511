import React from "react";
import "./assets/Header.css"; // Import the stylesheet

const Header = () => {
  return (
    <header className="header">
      <div className="header-content">
        <div className="logo">
          <img
            src="logo.jpg" // Replace with the path to your logo
            alt="Fit-Mate24 Logo"
            className="logo-img"
          />
        </div>
        {/* Email Link */}
        <div className="email">
          <a
            href="mailto:info@fit-mate24.com"
            className="email-link"
            target="_blank"
            rel="noopener noreferrer"
          >
            info@fit-mate24.com
          </a>
        </div>
      </div>
    </header>
  );
};

export default Header;
