import React from "react";
import "./assets/TermsAndConditions.css"; // Import the stylesheet

const TermsAndConditions = () => {
  return (
    <div className="terms-page">
      {/* Banner */}
      <div className="banner">
        <h1>Terms and Conditions</h1>
        <p>Effective Date: 01/01/2025</p>
      </div>

      {/* Content */}
      <div className="policy-content">
        <section>
          <h2>Introduction</h2>
          <p>
            Welcome to Fit-Mate24. By accessing or using our app, you agree to
            comply with and be bound by these Terms and Conditions, along with
            our Privacy Policy. If you do not agree with any part of these
            terms, please refrain from using our services.
          </p>
        </section>

        <section>
          <h2>1. Eligibility</h2>
          <p>
            Fit-Mate24 is only available to users who are at least 18 years old.
            By using our app, you confirm that you meet this age requirement.
          </p>
        </section>

        <section>
          <h2>2. Account Registration</h2>
          <p>
            You are responsible for maintaining the confidentiality of your
            account credentials and ensuring all information you provide during
            registration is accurate and up-to-date. Any misuse of your account
            is your responsibility.
          </p>
        </section>

        <section>
          <h2>3. Subscription and Payments</h2>
          <p>
            Fit-Mate24 operates on a subscription-based model. Payments are
            processed securely through third-party payment processors. Failure
            to make timely payments may result in suspension or termination of
            your access to the app.
          </p>
        </section>

        <section>
          <h2>4. Acceptable Use</h2>
          <p>By using Fit-Mate24, you agree not to:</p>
          <ul>
            <li>Engage in unlawful or harmful activities</li>
            <li>Harass, impersonate, or misuse the app to harm others</li>
            <li>
              Attempt to hack, modify, or disrupt the functionality of the app
            </li>
          </ul>
        </section>

        <section>
          <h2>5. Termination</h2>
          <p>
            We reserve the right to suspend or terminate your account if you
            violate these Terms and Conditions or engage in any behavior deemed
            inappropriate or harmful to our community.
          </p>
        </section>

        <section>
          <h2>6. Limitation of Liability</h2>
          <p>
            Fit-Mate24 is not responsible for any damages, losses, or claims
            arising from the use of our app. Use our app at your own risk.
          </p>
        </section>

        <section>
          <h2>7. Changes to Terms</h2>
          <p>
            We may update these Terms and Conditions from time to time. The
            updated terms will be effective upon posting within the app. It is
            your responsibility to review the terms regularly.
          </p>
        </section>

        <section>
          <h2>8. Contact Information</h2>
          <p>
            For any questions regarding these Terms and Conditions, please
            contact us:
          </p>
          <ul>
            <li>Email: info@fit-mate24.com</li>
            <li>Address: Torstrasse 80, Berlin 10119, Germany</li>
          </ul>
        </section>

        <section style={{ marginTop: "30px", textAlign: "center" }}>
          <p>
            By using Fit-Mate24, you agree to these Terms and Conditions. Thank
            you for being part of our fitness community.
          </p>
        </section>
      </div>
    </div>
  );
};

export default TermsAndConditions;
