import React from "react";
import "./assets/PrivacyPolicy.css";

const PrivacyPolicy = () => {
  return (
    <div>
      {/* Banner Section */}
      <header className="banner">
        <div className="banner-content">
          <h1>Privacy Policy</h1>
          <p>Effective Date: 01/01/2025</p>
        </div>
      </header>

      {/* Content Section */}
      <section className="policy-content">
        <h2>Introduction</h2>
        <p>
          Fit-Mate24 (hereinafter referred to as “we,” “our,” or “us”) is a
          subscription-based mobile application dedicated to connecting fitness
          enthusiasts. Your privacy is very important to us, and we are
          committed to protecting the personal data you share with us. This
          Privacy Policy explains how we collect, use, store, and protect your
          information when you use Fit-Mate24, in compliance with the General
          Data Protection Regulation (GDPR) and other applicable data
          protection laws in Germany.
        </p>
        <p>
          For any questions about this policy or your privacy rights, please
          contact us at <a href="mailto:info@fit-mate24.com">info@fit-mate24.com</a>.
        </p>

        <h2>1. Data Controller</h2>
        <p>
          Fit-Mate24, registered in Germany, acts as the Data Controller for the
          personal data collected through our app. This means we are
          responsible for how your data is collected, processed, and protected.
        </p>

        <h2>2. Data Collection</h2>
        <ul>
          <li>
            <strong>Account Information:</strong> When you create an account, we
            collect your name, email address, and age to verify that you meet
            our minimum age requirement of 18 years.
          </li>
          <li>
            <strong>Profile Information:</strong> You may choose to share
            additional profile details (e.g., fitness interests, location,
            profile picture) that will help you connect with other users.
          </li>
          <li>
            <strong>Payment Information:</strong> As a subscription app, we
            collect payment information via secure third-party payment
            processors. We do not store your payment details.
          </li>
          <li>
            <strong>Usage Information:</strong> We collect information about your
            activities within the app, such as profiles you view, messages you
            send, and interactions with other users.
          </li>
          <li>
            <strong>Device and Technical Information:</strong> To improve and
            secure the app experience, we collect technical data such as device
            type, IP address, app version, and other diagnostic information.
          </li>
        </ul>

        <h2>3. Legal Basis for Processing</h2>
        <p>
          We process your data based on the following legal grounds under GDPR:
        </p>
        <ul>
          <li>
            <strong>Contractual Necessity:</strong> To provide you with access to
            our app and the services offered within, as outlined in our Terms
            of Service.
          </li>
          <li>
            <strong>Consent:</strong> For optional features and certain data
            sharing, we will seek your consent. You have the right to withdraw
            your consent at any time.
          </li>
          <li>
            <strong>Legitimate Interests:</strong> For activities such as app
            improvement, fraud prevention, and ensuring the security of our
            users.
          </li>
          <li>
            <strong>Legal Obligations:</strong> We may process your data to comply
            with applicable legal and regulatory requirements.
          </li>
        </ul>

        <h2>4. How We Use Your Data</h2>
        <ul>
          <li>To provide and maintain app functionalities</li>
          <li>To process subscription payments</li>
          <li>To improve user experience by tailoring content</li>
          <li>To facilitate communication between users</li>
          <li>To conduct analytics and research</li>
          <li>To comply with legal requirements</li>
        </ul>

        <h2>5. Data Sharing and Disclosure</h2>
        <ul>
          <li>
            <strong>Service Providers:</strong> For hosting, payments, customer support, and analytics.
          </li>
          <li>
            <strong>Legal Compliance:</strong> Disclosure to authorities when required by law.
          </li>
          <li>
            <strong>Business Transfers:</strong> During mergers, acquisitions, or sales, subject to confidentiality.
          </li>
        </ul>

        <h2>6. Data Retention</h2>
        <p>
          We retain your data as long as necessary to fulfill its purposes or comply with legal obligations. When no longer required, the data will be deleted or anonymized.
        </p>

        <h2>7. Data Security</h2>
        <p>
          We employ industry-standard security measures, including encryption and regular audits, to protect your data. However, no method is 100% secure, and we strive to take all reasonable precautions.
        </p>

        <section style={{ marginBottom: "30px" }}>
          <h2>8. Your Rights Under GDPR</h2>
          <ul>
            <li>Access: Request a copy of your data.</li>
            <li>Rectification: Request corrections to inaccurate data.</li>
            <li>Deletion: Request deletion of data no longer needed.</li>
            <li>Restriction: Request limited processing of your data.</li>
            <li>Portability: Request data in a portable format.</li>
            <li>Objection: Object to data processing for specific reasons.</li>
          </ul>
          <p>
            To exercise these rights, contact us at <a href="mailto:info@fit-mate24.com">info@fit-mate24.com</a>.
          </p>
        </section>

        <section style={{ marginBottom: "30px" }}>
          <h2>9. Age Requirement</h2>
          <p>
            Fit-Mate24 is only available to users aged 18 and above. We do not knowingly collect data from individuals under 18. If we become aware of this, the data will be deleted promptly.
          </p>
        </section>

        <section style={{ marginBottom: "30px" }}>
          <h2>10. Changes to This Privacy Policy</h2>
          <p>
            We reserve the right to update this Privacy Policy to reflect changes in our practices or legal requirements. Updates will be posted within the app with the updated “Effective Date.”
          </p>
        </section>

        <section style={{ marginBottom: "30px" }}>
          <h2>11. Contact Information</h2>
          <p>
            For questions or concerns, contact us at:
            <br />
            <strong>Email:</strong> <a href="mailto:info@fit-mate24.com">info@fit-mate24.com</a>
            <br />
            <strong>Address:</strong> Torstrasse 80, Berlin 10119, Germany
          </p>
        </section>

        <section>
          <h2>12. Right to Lodge a Complaint</h2>
          <p>
            If you believe your data has not been handled properly, you can lodge a complaint with your local Data Protection Authority (DPA) in Germany.
          </p>
        </section>
        {/* Add more sections as needed */}
      </section>
    </div>
  );
};

export default PrivacyPolicy;
