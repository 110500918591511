import React from "react";
import "./assets/HomePage.css"; // Create a CSS file for styling

const Welcome = () => {
  return (
    <div className="home-container">
      {/* Main Content */}
      <main className="main-content">
        <h1>Welcome to Fit-Mate24</h1>
        <p>Let's connect and be fit together!</p>
        <div className="button-container">
          <a
            href="https://play.google.com/store"
            target="_blank"
            rel="noopener noreferrer"
            className="store-button google-button"
          >
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/7/78/Google_Play_Store_badge_EN.svg"
              alt="Google Play Store"
              className="store-badge"
            />
          </a>
          <a
            href="https://www.apple.com/app-store/"
            target="_blank"
            rel="noopener noreferrer"
            className="store-button apple-button"
          >
            <img
              src="https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg"
              alt="Apple App Store"
              className="store-badge"
            />
          </a>
        </div>
      </main>

      {/* Footer Section */}
      <footer className="footer">
        <p>&copy; {new Date().getFullYear()} Fit-Mate24. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default Welcome;
